<template>
  <div>
    <Header/>
    <div class="main-content">

      <div class="page-content">

        <!-- START JOB-DEATILS -->
        <section class="section mt-0 mt-md-4">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-8 mb-3">
                <h4 class="mb-1">ვაკანსიის გვერდი</h4>
              </div>
              <div class="col-4 mb-3 text-end">
                <a href="/#Vanacy" class="text-secondary"><i class="mdi mdi-arrow-left"></i> უკან</a>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-8">
                <div class="card job-detail overflow-hidden">
                  <div class="card-body p-4">
                    <div>
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex align-items-center">
                            <div v-if="!vacancyDetails.CompanyDetail.logo">
                              <img :src="vacancyDetails.CompanyDetail.logo" alt="" class="img-fluid rounded-3 rounded-3 me-3" style="height: 55px">
                            </div>
                            <div v-if="vacancyDetails.CompanyDetail.logo">
                              <img src="../../assets/images/no-logo.png" alt="" class="img-fluid rounded-3 rounded-3 me-3" style="height: 55px">
                            </div>
                            <h5 class="mb-1">{{ vacancyDetails.position }}</h5>
                          </div>
                        </div>
                      </div><!--end row-->
                    </div>

                    <div class="mt-4">
                      <div class="row g-2">
                        <div class="col-lg-6">
                          <div class="border p-3">
                            <p class="text-muted fs-13 mb-0">სამუშაო გრაფიკი</p>
                            <span data-v-5d924f96="" class="badge bg-custom-success fs-13 mt-1">{{ vacancyDetails.work_schedule }}</span>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="border p-3">
                            <p class="text-muted fs-13 mb-0">სამუშაო საათები</p>
                            <p class="fw-medium mb-0 mdi mdi-clock-outline"> {{ vacancyDetails.time_at }} დან - {{ vacancyDetails.time_to }} მდე</p>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="border p-3">
                            <p class="text-muted fs-13 mb-0">ანაზღაურების ტიპი</p>
                            <p class="mdi mdi-wallet-travel fw-medium mb-0"> ფიქსირებული + ბონუსი</p>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="border rounded-end p-3">
                            <p class="text-muted fs-13 mb-0">ფიქსირებული თანხა</p>
                            <p class="fw-medium mb-0 mdi mdi-wallet-outline"> {{ vacancyDetails.fixed_amount }} ₾</p>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="border rounded-end p-3">
                            <p class="text-muted fs-13 mb-0">ბონუსი</p>
                            <p class="fw-medium mb-0 mdi mdi-wallet-outline"> მინ {{ vacancyDetails.min_bonus }} ₾ - მაქს {{ vacancyDetails.max_bonus }} ₾</p>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="border rounded-end p-3">
                            <p class="text-muted fs-13 mb-0">ვაკანსიის ვადა</p>
                            <p class="fw-medium mb-0 mdi mdi-timer-outline"> {{ vacancyDetails.start_at }} დან - {{ vacancyDetails.end_at }} მდე</p>
                          </div>
                        </div>
                      </div>
                    </div><!--end Experience-->

                    <div class="mt-4">
                      <h5 class="mb-3">რატომ უნდა იმუშაო ჩვენს კომპანიაში?</h5>
                      <div class="job-detail-desc">
                        <p class="text-muted mb-0">აქ გამოჩნდება აღწერა თუ რატომ უნდა გააგზავნო აპლიკაცია.</p>
                      </div>
                    </div>

                    <div class="mt-4">
                      <h5 class="mb-3">რა უნდა გააკეთო?</h5>
                      <div class="job-detail-desc">
                        <p class="text-muted mb-0">აქ გამოჩნდება ის თუ რა უნდა აკეთოს კადრმა.</p>
                      </div>
                    </div>

                    <div class="mt-4">
                      <h5 class="mb-3">რას გასწავლით?</h5>
                      <div class="job-detail-desc">
                        <p class="text-muted mb-0">აქ გამოჩნდება ის თუ რას ასწავლის კომპანია</p>
                      </div>
                    </div>

                    <div class="mt-4">
                      <h5 class="mb-3">როგორ იზომება შენი მუშაობის შედეგები?</h5>
                      <div class="job-detail-desc">
                        <p class="text-muted mb-0">აქ გამოჩნდება იზომება თუ არა მუშაობის შედეგები.</p>
                      </div>
                    </div>

                    <div class="mt-4">
                      <h5 class="mb-3">რა პერსპექტივა გაქვთ ჩვენს კომპანიაში?</h5>
                      <div class="job-detail-desc mt-2">
                        <p class="text-muted">აქ გამოჩნდება პერსპექტივის აღწერის ჩამონათვალი</p>
                      </div>
                    </div>

                    <div class="mt-4">
                      <h5 class="mb-3">რა მოთხოვნებს უნდა აკმაყოფილებდეთ </h5>
                      <div class="job-detail-desc mt-2">
                        <p class="text-muted">აქ გამოჩნდება მოთხოვნის აღწერა</p>
                      </div>
                    </div>

                    <div class="mt-4">
                      <h5 class="mb-3">სამუშაო დღეები</h5>
                      <div class="job-details-desc">
                        <ul class="job-detail-list list-unstyled mb-0 text-muted">
                          <li><i class="uil uil-circle"></i> <span class="font-weight-bold">ორშაბათი:</span> <span>10 სთ.დან - 6 სთ.მდე</span></li>
                          <li><i class="uil uil-circle"></i> <span class="font-weight-bold">სამშაბათი:</span> <span>10 სთ.დან - 6 სთ.მდე</span></li>
                          <li><i class="uil uil-circle"></i> <span class="font-weight-bold">ოთხშაბათი:</span> <span>10 სთ.დან - 6 სთ.მდე</span></li>
                          <li><i class="uil uil-circle"></i> <span class="font-weight-bold">ხუთშაბათი:</span> <span>10 სთ.დან - 6 სთ.მდე</span></li>
                          <li><i class="uil uil-circle"></i> <span class="font-weight-bold">პარასკევი:</span> <span>10 სთ.დან - 6 სთ.მდე</span></li>
                          <li><i class="uil uil-circle"></i> <span class="font-weight-bold">შაბათი:</span> <span>დასვენება</span></li>
                          <li><i class="uil uil-circle"></i> <span class="font-weight-bold">კვირა:</span> <span>დასვენება</span></li>
                        </ul>
                      </div>
                    </div>

                    <div class="mt-4">
                      <p>აქ ჩაიწერება დამატებითი ჩასაწერი</p>
                    </div>
                  </div><!--end card-body-->
                </div><!--end job-detail-->


              </div><!--end col-->

              <div class="col-lg-4 mt-4 mt-lg-0">
                <!--start side-bar-->
                <div class="side-bar ms-lg-4">
                  <div class="card job-overview">
                    <div class="card-body p-4">
                      <h6 class="fs-17">სამუშაოს მიმოხილვა</h6>
                      <ul class="list-unstyled mt-4 mb-0">
                        <li>
                          <div class="d-flex mt-4">
                            <i class="uil uil-briefcase icon bg-soft-secondary"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">სფერო</h6>
                              <p class="text-muted mb-0">{{vacancyDetails.SphereDetail.text}}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex mt-4">
                            <i class="uil uil-user icon bg-soft-secondary"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">სამუშაო პოზიცია</h6>
                              <p class="text-muted mb-0">{{vacancyDetails.position}}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex mt-4">
                            <i class="uil uil-building icon bg-soft-secondary"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">ინდუსტრია</h6>
                              <p class="text-muted mb-0">{{vacancyDetails.IndustryDetail.text}}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex mt-4">
                            <i class="uil uil-location-point icon bg-soft-secondary"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">ლოკაცია</h6>
                              <p class="text-muted mb-0"> {{vacancyDetails.CompanyDetail.address}}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex mt-4">
                            <i class="uil uil-star-half-alt icon bg-soft-secondary"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">გამოცდილება</h6>
                              <p class="text-muted mb-0"> {{vacancyDetails.work_experience}} წელი</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex mt-4">
                            <i class="uil uil-history icon bg-soft-secondary"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">ვაკანსიის დადების დარიღი</h6>
                              <p class="text-muted mb-0">{{vacancyDetails.created_at}}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div class="mt-3">
                        <a href="#applyNow" data-bs-toggle="modal" class="btn btn-custom-primary btn-hover w-100 mt-2">შეავსე აპლიკაცია <i class="uil uil-arrow-right"></i></a>
                      </div>
                    </div><!--end card-body-->
                  </div><!--end job-overview-->

                  <div class="card company-profile mt-4">
                    <div class="card-body p-4">
                      <div class="text-center">
                        <div v-if="!vacancyDetails.CompanyDetail.logo">
                          <img :src="vacancyDetails.CompanyDetail.logo" alt="" class="img-fluid rounded-3 rounded-3 me-3" style="height: 55px">
                        </div>
                        <div v-if="vacancyDetails.CompanyDetail.logo">
                          <img src="../../assets/images/no-logo.png" alt="" class="img-fluid rounded-3 rounded-3 me-3" style="height: 55px">
                        </div>

                        <div class="mt-4">
                          <h6 class="fs-17 mb-1">საქართველოს ბანკი</h6>
                        </div>
                      </div>
                      <ul class="list-unstyled mt-4">
                        <li class="mt-3">
                          <div class="d-flex">
                            <i class="uil uil-globe text-secondary fs-4"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">ვებ გვერდი</h6>
                              <a :href="vacancyDetails.CompanyDetail.website" target="_blank" class="text-muted fs-14 mb-0">{{vacancyDetails.CompanyDetail.website}}</a>
                            </div>
                          </div>
                        </li>
                        <li class="mt-3">
                          <div class="d-flex">
                            <i class="uil uil-map-marker text-secondary fs-4"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">ადგილმდებარეობა</h6>
                              <p class="text-muted fs-14 mb-0">{{vacancyDetails.CompanyDetail.address}}</p>
                            </div>
                          </div>
                        </li>
                        <li class="mt-3">
                          <div class="d-flex">
                            <i class="uil uil-facebook-f text-secondary fs-4"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">Facebook</h6>
                              <a :href="vacancyDetails.CompanyDetail.facebook" target="_blank" class="text-muted fs-14 mb-0">გადასვლა</a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!--end side-bar-->
              </div><!--end col-->
            </div><!--end row-->
          </div><!--end container-->
        </section>
        <!-- START JOB-DEATILS -->


      </div>
      <!-- End Page-content -->
      </div>

    <!-- START APPLY MODAL -->
    <div class="modal fade" id="applyNow" tabindex="-1" aria-labelledby="applyNow" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-5">
            <div class="text-center mb-4">
              <h5 class="modal-title" id="staticBackdropLabel">აპლიკაციის შევსება</h5>
            </div>
            <div class="position-absolute end-0 top-0 p-3">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="addVacancy">
              <div class="mb-3">
                <label class="form-label">სახელი და გვარი</label>
                <input type="text" class="form-control" v-model="form.name" placeholder="სახელი და გვარი">
              </div>
              <div class="mb-3">
                <label class="form-label">ელ.ფოსტა</label>
                <input type="email" class="form-control" v-model="form.email" placeholder="ელ.ფოსტა">
              </div>
              <div class="mb-3">
                <label class="form-label">მობილური</label>
                <input type="number" class="form-control" v-model="form.mobile" placeholder="მობილური">
              </div>
              <div class="mb-3">
                <label class="form-label">მესიჯი</label>
                <textarea class="form-control" v-model="form.message"></textarea>
              </div>
              <div class="mb-4">
                <label class="form-label">ატვირთე შენი CV</label>
                <vue-dropzone ref="myVueDropzoneLogo"  id="dropzone-logo" :headers="dropzoneCVOptions.headers" v-on:vdropzone-success="uploadCVSuccess" :options="dropzoneCVOptions"></vue-dropzone>
              </div>
              <button type="submit" class="btn btn-custom-primary w-100">გააგზავნე აპლიკაცია</button>
            </form>
         </div>
        </div>
      </div>
    </div><!-- END APPLY MODAL -->

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: "VacancyDetails",
  data() {
    return {
      vacancyDetails: null,
      loading: true,
      errored: false,
      form:{
          'name': '',
          'file': '',
          'email': '',
          'mobile': '',
          'message': '',
          'id': ''
      },
      dropzoneCVOptions: {

        url: 'https://recruting.dkcapital.ge/api/public/vacancy/upload',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
        },
        thumbnailWidth: 200,
        addRemoveLinks: true,

      },
    }
  },
  methods: {
    vacancyDetail() {
      axios
          .get('https://recruting.dkcapital.ge/api/public/vacancy/get_by_id/' + this.$route.params.id)
          .then(res => {
            this.vacancyDetails = res.data
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    uploadCVSuccess: function(file, response) {
      this.form.file=response.success;
    },
    addVacancy() {
      axios.post('https://recruting.dkcapital.ge/api/public/vacancy/add_cv/'+ this.$route.params.id , {
        name: this.form.name,
        file: this.form.file,
        email: this.form.email,
        mobile: this.form.mobile,
        massage: this.form.massage
        }).then(res => console.log(res))
        .catch(error => console.log(error))
    },
  },
  mounted() {
    this.vacancyDetail()
    this.addVacancy()
    console.log(this.$route.params.id)
  },
  components: {
    Header,
    Footer,
    vueDropzone: vue2Dropzone
  }
}
</script>

<style scoped>

</style>