<template>
  <!-- Begin page -->
  <div>

    <Header/>

    <div class="main-content">

      <div class="page-content">

        <!-- START HOME -->
        <section class="bg-home2" id="home">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-7">
                <div class="mb-4 pb-3 me-lg-5">

                  <h1 class="display-5 fw-semibold mb-3">ჩვენ ვეხმარებით ადამიანებს დასაქმებაში და მეტი შემოსავლების მიღებაში <br></h1>
                  <span class="display-5 text-custom-primary fw-bold">დავით კენჭაძე</span>
                  <p class="lead text-muted mb-0 mt-3">ვაკანსიები რომლებიც შეიძლება საინტერესო იყოს თქვენთვის ან თქვენი მეგობრებისთვის</p>
                </div>
              </div>
              <!--end col-->
              <div class="col-lg-5">
                <div class="mt-5 mt-md-0">
                  <img src="../../assets/images/process-04.jpg" alt="" class="home-img w-100 rounded-circle" />
                </div>
              </div><!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end container-->
        </section>
        <!-- End Home -->

        <!-- START SHAPE -->
        <div class="position-relative">
          <div class="shape">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1440" height="150" preserveAspectRatio="none" viewBox="0 0 1440 220">
              <g mask="url(&quot;#SvgjsMask1004&quot;)" fill="none">
                <path d="M 0,213 C 288,186.4 1152,106.6 1440,80L1440 250L0 250z" fill="rgba(255, 255, 255, 1)"></path>
              </g>
              <defs>
                <mask id="SvgjsMask1004">
                  <rect width="1440" height="250" fill="#ffffff"></rect>
                </mask>
              </defs>
            </svg>
          </div>
        </div>
        <!-- END SHAPE -->

        <!-- START JOB-LIST -->
        <section class="section" id="Vanacy">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <div class="section-title text-center mb-4 pb-2">
                  <h4 class="title">ვაკანსიები</h4>
                </div>
              </div><!--end col-->
            </div><!--end row-->
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <ul class="job-list-menu nav nav-pills nav-justified flex-column flex-sm-row mb-4" id="pills-tab"
                    role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="recent-jobs-tab" data-bs-toggle="pill"
                            data-bs-target="#recent-jobs" type="button" role="tab" aria-controls="recent-jobs"
                            aria-selected="true">გამოცდილების გარეშე</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="remote-jobs-tab" data-bs-toggle="pill"
                            data-bs-target="#remote-jobs" type="button" role="tab" aria-controls="remote-jobs"
                            aria-selected="false">დამწყები</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="part-time-tab" data-bs-toggle="pill"
                            data-bs-target="#part-time" type="button" role="tab" aria-controls="part-time"
                            aria-selected="false">საშუალო დონე</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="full-time-tab" data-bs-toggle="pill"
                            data-bs-target="#full-time" type="button" role="tab" aria-controls="full-time"
                            aria-selected="false">პროფესიონალი</button>
                  </li>
                </ul>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
            <div class="row">
              <div class="col-lg-12">
                <div class="tab-content" id="pills-tabContent">
                  <!-- Recent Jobs / ბოლო ვაკანსიები -->
                  <div class="tab-pane fade show active" id="recent-jobs" role="tabpanel"
                       aria-labelledby="recent-jobs-tab">

                    <div v-if="loading">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>

                    <!--job-box-->
                    <div class="job-box card mt-4" v-for="lastRecord in lastRecords" :key="lastRecord.id">
                      <div class="p-4">
                        <div class="row align-items-center">
                          <div class="col-md-2">
                            <div v-if="!lastRecord.CompanyDetail.logo">
                              <a href="VacancyDetails"><img :src="lastRecord.CompanyDetail.logo" alt="" class="img-fluid rounded-3"></a>
                            </div>
                            <div v-if="lastRecord.CompanyDetail.logo">
                              <a href="VacancyDetails"><img src="../../assets/images/no-logo.png" alt="" class="img-fluid rounded-3"></a>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-3">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1"><a href="VacancyDetails" class="text-dark">{{ lastRecord.CompanyDetail.name }}</a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">dkcapital</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-3">
                            <div class="d-flex mb-2">
                              <div class="flex-shrink-0">
                                <i class="mdi mdi-map-marker text-custom-primary me-1"></i>
                              </div>
                              <p class="text-muted mb-0">{{ lastRecord.CompanyDetail.address }}</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-2">
                            <div>
                              <p class="text-muted mb-2"><span class="text-custom-primary">₾ </span>{{ lastRecord.fixed_amount }}</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-2">
                            <div>
                              <span class="badge bg-custom-success fs-13 mt-1">სრული განაკვეთი</span>
                              <span class="badge bg-custom-warning fs-13 mt-1">დისტანციური</span>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                      <div class="p-3 bg-light">
                        <div class="row">
                          <div class="col-md-6">
                            <div>
                              <p class="text-muted mb-0"><span class="text-dark">გამოცდილება :</span> {{ lastRecord.work_experience }} წელი</p>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="text-start text-md-end">
                              <a :href="'VacancyDetails/'+lastRecord.id" class="primary-link">დეტალურად <i
                                  class="mdi mdi-chevron-double-right"></i></a>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                    </div>
                    <!--end job-box-->

                    <div class="text-center mt-4 pt-2">
                      <a href="VacancyList" class="btn btn-custom-primary btn-hover">ნახე მეტი <i class="uil uil-arrow-right"></i></a>
                    </div>

                  </div>
                  <!--end Recent Jobs / ბოლო ვაკანსიები -->

                  <!-- Remote Jobs / დისტანციური ვაკანსიები -->
                  <div class="tab-pane fade" id="remote-jobs" role="tabpanel"
                       aria-labelledby="remote-jobs-tab">

                    <div v-if="loading">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>

                    <!--job-box-->
                    <div class="job-box card mt-4" v-for="remoteRecord in remoteRecords" :key="remoteRecord.id">
                      <div class="p-4">
                        <div class="row align-items-center">
                          <div class="col-md-2">
                            <div v-if="!remoteRecord.CompanyDetail.logo">
                              <a href="VacancyDetails"><img :src="remoteRecord.CompanyDetail.logo" alt="" class="img-fluid rounded-3"></a>
                            </div>
                            <div v-if="remoteRecord.CompanyDetail.logo">
                              <a href="VacancyDetails"><img src="../../assets/images/no-logo.png" alt="" class="img-fluid rounded-3"></a>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-3">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1"><a href="VacancyDetails" class="text-dark">{{ remoteRecord.CompanyDetail.name }}</a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">dkcapital</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-3">
                            <div class="d-flex mb-2">
                              <div class="flex-shrink-0">
                                <i class="mdi mdi-map-marker text-custom-primary me-1"></i>
                              </div>
                              <p class="text-muted mb-0">{{ remoteRecord.CompanyDetail.address }}</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-2">
                            <div>
                              <p class="text-muted mb-2"><span class="text-custom-primary">₾ </span>{{ remoteRecord.fixed_amount }}</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-2">
                            <div>
                              <span class="badge bg-custom-success fs-13 mt-1">სრული განაკვეთი</span>
                              <span class="badge bg-custom-warning fs-13 mt-1">დისტანციური</span>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                      <div class="p-3 bg-light">
                        <div class="row">
                          <div class="col-md-6">
                            <div>
                              <p class="text-muted mb-0"><span class="text-dark">გამოცდილება :</span> {{ remoteRecord.work_experience }} წელი</p>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="text-start text-md-end">
                              <a href="VacancyDetails" class="primary-link">დეტალურად <i
                                  class="mdi mdi-chevron-double-right"></i></a>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                    </div>
                    <!--end job-box-->

                    <div class="text-center mt-4 pt-2">
                      <a href="VacancyDetails" class="btn btn-custom-primary btn-hover">ნახე მეტი <i class="uil uil-arrow-right"></i></a>
                    </div>
                  </div>
                  <!--end featured-jobs-tab-->

                  <!-- Part Time Jobs / ნახევარი განაკვეთის ვაკანსიები -->
                  <div class="tab-pane fade" id="part-time" role="tabpanel" aria-labelledby="part-time-tab">

                    <div v-if="loading">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>

                    <!--job-box-->
                    <div class="job-box card mt-4" v-for="halfRecord in halfRecords" :key="halfRecord.id">
                      <div class="p-4">
                        <div class="row align-items-center">
                          <div class="col-md-2">
                            <div v-if="!halfRecord.CompanyDetail.logo">
                              <a href="VacancyDetails"><img :src="halfRecord.CompanyDetail.logo" alt="" class="img-fluid rounded-3"></a>
                            </div>
                            <div v-if="halfRecord.CompanyDetail.logo">
                              <a href="VacancyDetails"><img src="../../assets/images/no-logo.png" alt="" class="img-fluid rounded-3"></a>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-3">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1"><a href="VacancyDetails" class="text-dark">{{ halfRecord.CompanyDetail.name }}</a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">dkcapital</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-3">
                            <div class="d-flex mb-2">
                              <div class="flex-shrink-0">
                                <i class="mdi mdi-map-marker text-custom-primary me-1"></i>
                              </div>
                              <p class="text-muted mb-0">{{ halfRecord.CompanyDetail.address }}</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-2">
                            <div>
                              <p class="text-muted mb-2"><span class="text-custom-primary">₾ </span>{{ halfRecord.fixed_amount }}</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-2">
                            <div>
                              <span class="badge bg-custom-success fs-13 mt-1">სრული განაკვეთი</span>
                              <span class="badge bg-custom-warning fs-13 mt-1">დისტანციური</span>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                      <div class="p-3 bg-light">
                        <div class="row">
                          <div class="col-md-6">
                            <div>
                              <p class="text-muted mb-0"><span class="text-dark">გამოცდილება :</span> 1 წელი</p>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="text-start text-md-end">
                              <a href="VacancyDetails" class="primary-link">დეტალურად <i
                                  class="mdi mdi-chevron-double-right"></i></a>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                    </div>
                    <!--end job-box-->

                    <div class="text-center mt-4 pt-2">
                      <a href="VacancyDetails" class="btn btn-custom-primary btn-hover">ნახე მეტი <i class="uil uil-arrow-right"></i></a>
                    </div>
                  </div>
                  <!--end Part Time Jobs / ნახევარი განაკვეთის ვაკანსიები-->

                  <!-- Full Time Jobs / სრული განაკვეთის ვაკანსიები -->
                  <div class="tab-pane fade" id="full-time" role="tabpanel" aria-labelledby="full-time-tab">

                    <div v-if="loading">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>

                    <!--job-box-->
                    <div class="job-box card mt-4" v-for="fullRecord in fullRecords" :key="fullRecord.id">
                      <div class="p-4">
                        <div class="row align-items-center">
                          <div class="col-md-2">
                            <div v-if="!fullRecord.CompanyDetail.logo">
                              <a href="VacancyDetails"><img :src="fullRecord.CompanyDetail.logo" alt="" class="img-fluid rounded-3"></a>
                            </div>
                            <div v-if="fullRecord.CompanyDetail.logo">
                              <a href="VacancyDetails"><img src="../../assets/images/no-logo.png" alt="" class="img-fluid rounded-3"></a>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-3">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1"><a href="VacancyDetails" class="text-dark">{{ fullRecord.CompanyDetail.name }}</a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">dkcapital</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-3">
                            <div class="d-flex mb-2">
                              <div class="flex-shrink-0">
                                <i class="mdi mdi-map-marker text-custom-primary me-1"></i>
                              </div>
                              <p class="text-muted mb-0">{{ fullRecord.CompanyDetail.address }}</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-2">
                            <div>
                              <p class="text-muted mb-2"><span class="text-custom-primary">₾ </span>{{ lastRecord.fixed_amount }}</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-2">
                            <div>
                              <span class="badge bg-custom-success fs-13 mt-1">სრული განაკვეთი</span>
                              <span class="badge bg-custom-warning fs-13 mt-1">დისტანციური</span>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                      <div class="p-3 bg-light">
                        <div class="row">
                          <div class="col-md-6">
                            <div>
                              <p class="text-muted mb-0"><span class="text-dark">გამოცდილება :</span> 1 წელი</p>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="text-start text-md-end">
                              <a href="VacancyDetails" class="primary-link">დეტალურად <i
                                  class="mdi mdi-chevron-double-right"></i></a>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                    </div>
                    <!--end job-box-->

                    <div class="text-center mt-4 pt-2">
                      <a href="VacancyDetails" class="btn btn-custom-primary btn-hover">ნახე მეტი <i class="uil uil-arrow-right"></i></a>
                    </div>
                  </div>
                  <!--end Full Time Jobs / სრული განაკვეთის ვაკანსიები-->

                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end container-->
        </section>
        <!-- END JOB-LIST -->

        <!-- START APPLY MODAL -->
        <div class="modal fade" id="applyNow" tabindex="-1" aria-labelledby="applyNow" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body p-5">
                <div class="text-center mb-4">
                  <h5 class="modal-title" id="staticBackdropLabel">Apply For This Job</h5>
                </div>
                <div class="position-absolute end-0 top-0 p-3">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mb-3">
                  <label for="nameControlInput" class="form-label">Name</label>
                  <input type="text" class="form-control" id="nameControlInput" placeholder="Enter your name">
                </div>
                <div class="mb-3">
                  <label for="emailControlInput2" class="form-label">Email Address</label>
                  <input type="email" class="form-control" id="emailControlInput2" placeholder="Enter your email">
                </div>
                <div class="mb-3">
                  <label for="mobileControlInput2" class="form-label">Mobile</label>
                  <input type="number" class="form-control" id="mobileControlInput2" placeholder="Enter Your number">
                </div>
                <div class="mb-3">
                  <label class="form-label">Message</label>
                  <textarea class="form-control"></textarea>
                </div>
                <div class="mb-4">
                  <label class="form-label" for="inputGroupFile01">Upload Your CV</label>
                  <input type="file" class="form-control" id="inputGroupFile01">
                </div>
                <button type="submit" class="btn btn-orange w-100">Send Application</button>
              </div>
            </div>
          </div>
        </div><!-- END APPLY MODAL -->

      </div>
      <!-- End Page-content -->

      <Footer/>

      <!--start back-to-top-->
      <button onclick="topFunction()" id="back-to-top">
        <i class="mdi mdi-arrow-up"></i>
      </button>
      <!--end back-to-top-->
    </div>
    <!-- end main content-->

  </div>
  <!-- END layout-wrapper -->
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: "Main",
  data() {
    return {
      lastRecords: null,
      remoteRecords: null,
      halfRecords: null,
      fullRecords: null,
      loading: true,
      errored: false
    }
  },
  components: {
    Header,
    Footer
  },
  methods: {
    lastRecord() {
      axios
      .get('https://recruting.dkcapital.ge/api/public/vacancy/get_last_records')
      .then(res => {
        this.lastRecords = res.data
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.loading = false)
      },
    remoteRecord() {
      axios
          .get('https://recruting.dkcapital.ge/api/public/vacancy/get_record_statuses/remote')
          .then(res => {
            this.remoteRecords = res.data
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    halfRecord() {
      axios
          .get('https://recruting.dkcapital.ge/api/public/vacancy/get_record_statuses/half')
          .then(res => {
            this.halfRecords = res.data
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    fullRecord() {
      axios
          .get('https://recruting.dkcapital.ge/api/public/vacancy/get_record_statuses/full')
          .then(res => {
            this.fullRecords = res.data
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    }
    },
  mounted() {
    this.lastRecord();
    this.remoteRecord();
    this.halfRecord();
    this.fullRecord();
  }
}
</script>

<style scoped>

</style>