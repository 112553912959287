<template>
  <div>
    <!-- START SUBSCRIBE -->
    <section class="bg-subscribe">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-6">
            <div class="text-center text-lg-start">
              <h4 class="text-white">მიიღე ახალი ვაკანსიების შეტყობინებები!</h4>
              <p class="text-white-50 mb-0">გამოიწერე და მიიღე ყველა ვაკანსიის შეტყობინება.</p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mt-4 mt-lg-0">
              <form class="subscribe-form" action="#">
                <div class="input-group justify-content-center justify-content-lg-end">
                  <input type="text" class="form-control" id="subscribe" placeholder="მიუთითე შენი ელ.ფოსტა">
                  <button class="btn btn-custom-primary text-white" type="button" id="subscribebtn">გამოწერა</button>
                </div>
              </form><!--end form-->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="email-img d-none d-lg-block">
        <img src="../assets/images/subscribe.png" alt="" class="img-fluid">
      </div>
    </section>
    <!-- END SUBSCRIBE -->

    <!-- START FOOTER -->
    <section class="bg-footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="footer-item mt-4 mt-lg-0 me-lg-5">
              <h4 class="text-white mb-4">David Kenchadze</h4>
            </div>
          </div>
          <div class="col-lg-6 text-lg-end">
            <div class="footer-item mt-4 mt-lg-0 me-lg-5">
              <ul class="footer-social-menu list-inline mb-0">
                <li class="list-inline-item"><a target="_blank" href="https://www.facebook.com/DavidKenchadzeOfficial"><i class="uil uil-facebook-f"></i></a></li>
              </ul>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end container-->
    </section>
    <!-- END FOOTER -->
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>