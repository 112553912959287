<template>
  <div>
    <Header/>


    <div class="main-content">

      <div class="page-content">

        <!-- START JOB-LIST -->
        <section class="section mt-4">
          <div class="container">
            <div class="row">
              <div class="col-12 mb-3">
                <h4 class="mb-1">ვაკანსიები</h4>
              </div>
              <div class="col-12">
                <div class="me-lg-5">
                  <div class="job-list-header">
                    <form action="#">
                      <div class="row g-2">
                        <div class="col-md-4">
                          <div class="filler-job-form">
                            <i class="uil uil-location-point"></i>
                            <select class="form-select" @change="getResults();" v-model="filter.location_id">
                              <option value="location" selected disabled>აირჩიე ლოკაცია</option>
                              <option v-for="locationData in locationDatas" :value="locationData.id" :key="locationData.id">{{locationData.name}}</option>
                            </select>
                          </div>
                        </div><!--end col-->
                        <div class="col-md-4">
                          <div class="filler-job-form">
                            <i class="uil uil-clipboard-notes"></i>
                            <select class="form-select" v-model="filter.industry_id" @change="getResults();">
                              <option value="4" selected disabled>აირჩიე კატეგორიები</option>
                              <option v-for="industryData in industryDatas" :value="industryData.id" :key="industryData.id">{{industryData.text}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="filler-job-form">
                            <i class="uil uil-clipboard"></i>
                            <select class="form-select " @change="getResults();" v-model="filter.work_schedule" data-trigger name="choices-single-categories" id="choices-single-categories" aria-label="Default select example">
                              <option value="4" selected disabled>აირჩიე გრაფიკი</option>
                              <option value="remote">დისტანციური</option>
                              <option value="half">ნახევარი განაკვეთი</option>
                              <option value="full">სრული განაკვეთი</option>
                            </select>
                          </div>
                        </div>
                      </div><!--end row-->
                    </form>
                  </div><!--end job-list-header-->
                  <!-- Job-list -->
                  <div>

                    <div v-if="loading">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>

                    <!--job-box-->
                    <div class="job-box card mt-4" v-for="vacancyList in ResponseData.data" :key="vacancyList.id">
                      <div class="p-4">
                        <div class="row align-items-center">
                          <div class="col-md-2">
                            <div class="text-center mb-4 mb-md-0">
                              <div v-if="!vacancyList.CompanyDetail.logo">
                                <a href="VacancyDetails"><img :src="vacancyList.CompanyDetail.logo" alt="" class="img-fluid rounded-3"></a>
                              </div>
                              <div v-if="vacancyList.CompanyDetail.logo">
                                <a href="VacancyDetails"><img src="../../assets/images/no-logo.png" alt="" class="img-fluid rounded-3"></a>
                              </div>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-3">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1"><a href="VacancyDetails" class="text-dark"></a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">{{ vacancyList.CompanyDetail.name }}</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-3">
                            <div class="d-flex mb-2">
                              <div class="flex-shrink-0">
                                <i class="mdi mdi-map-marker text-custom-primary me-1"></i>
                              </div>
                              <p class="text-muted mb-0">{{ vacancyList.CompanyDetail.address }}</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-2">
                            <div>
                              <p class="text-muted mb-2"><span class="text-custom-primary">₾ </span>{{ vacancyList.fixed_amount }}</p>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-2">
                            <div>
                              <span class="badge bg-custom-success fs-13 mt-1">სრული განაკვეთი</span>
                              <span class="badge bg-custom-warning fs-13 mt-1">დისტანციური</span>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                      <div class="p-3 bg-light">
                        <div class="row">
                          <div class="col-md-6">
                            <div>
                              <p class="text-muted mb-0"><span class="text-dark">გამოცდილება :</span> 1
                                - 2 წელი</p>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="text-start text-md-end">
                              <a href="VacancyDetails" class="primary-link">დეტალურად <i
                                  class="mdi mdi-chevron-double-right"></i></a>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                    </div>
                    <!--end job-box-->




                  </div>
                  <!-- End Job-list -->
                  <div class="row">
                    <div class="col-lg-12 mt-4 pt-2">
                      <pagination :data="ResponseData" 	 @pagination-change-page="getResults">
                        <template #prev-nav>
                          <span> < </span>
                        </template>
                        <template #next-nav>
                          <span> > </span>
                        </template>
                      </pagination>
                    </div><!--end col-->
                  </div><!-- end row -->
                </div>
              </div><!--end col-->

              <!-- START SIDE-BAR -->
              <!-- END SIDE-BAR -->
            </div><!--end row-->
          </div><!--end container-->
        </section>
        <!-- END JOB-LIST -->

        <!-- START APPLY MODAL -->
        <div class="modal fade" id="applyNow" tabindex="-1" aria-labelledby="applyNow" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body p-5">
                <div class="text-center mb-4">
                  <h5 class="modal-title" id="staticBackdropLabel">Apply For This Job</h5>
                </div>
                <div class="position-absolute end-0 top-0 p-3">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mb-3">
                  <label for="nameControlInput" class="form-label">Name</label>
                  <input type="text" class="form-control" id="nameControlInput" placeholder="Enter your name">
                </div>
                <div class="mb-3">
                  <label for="emailControlInput2" class="form-label">Email Address</label>
                  <input type="email" class="form-control" id="emailControlInput2" placeholder="Enter your email">
                </div>
                <div class="mb-3">
                  <label  class="form-label">Message</label>
                  <textarea class="form-control" id="messageControlTextarea" rows="4" placeholder="Enter your message"></textarea>
                </div>
                <div class="mb-4">
                  <label class="form-label" for="inputGroupFile01">Resume Upload</label>
                  <input type="file" class="form-control" id="inputGroupFile01">
                </div>
                <button type="submit" class="btn btn-primary w-100">Send Application</button>
              </div>
            </div>
          </div>
        </div><!-- END APPLY MODAL -->

      </div>
      <!-- End Page-content -->
    </div>
    <!-- end main content-->
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: "VacancyDetails",
  data() {
    return {

      vacancyLists: null,
      locationDatas: null,
      industryDatas: null,
      loading: true,
      errored: false,
      ResponseData:{},
      filter:{

      }
    }
  },
  components: {
    Header,
    Footer
  },
  methods: {

    locationData() {
      axios
          .get('https://recruting.dkcapital.ge/api/public/vacancy/LoadLocation')
          .then(response => {
            this.locationDatas = response.data
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    industryData() {
      axios
          .get('https://recruting.dkcapital.ge/api/public/vacancy/LoadIndustry')
          .then(response => {
            this.industryDatas = response.data
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },

    getResults(page = 1) {
      this.isLoading = true;
      axios.get('https://recruting.dkcapital.ge/api/public/vacancy/search'+'?page=' + page, {
        params: {
          location_id: this.filter.location_id,
          industry_id: this.filter.industry_id,
          work_schedule: this.filter.work_schedule,

        }
      })
          .then(response => {
            this.isLoading = false;
            this.ResponseData = response.data.vacancy;
          });
    },


  },
  mounted () {
    this.getResults();
    this.locationData();
    this.industryData();
  },
}
</script>

<style >
.sr-only{
  display:none!important;
}
</style>