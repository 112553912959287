<template>
  <div>
    <Header/>
    <div class="main-content">
      <div class="page-content">
        <section class="section mt-4">
          <div class="container">
            <div class="row">
              <div class="col-12 mb-3">
                <h4 class="mb-1">ვაკანსიის განთავსება</h4>
              </div>
              <div class="col-12 mt-4">
                აქ იქნება კონტენტი ვაკანსიის განთავსების შესახებ
              </div>
              <div class="col-12 mt-4">
                <a href="#" class="btn btn-custom-primary mdi mdi-rocket-launch-outline"> რეგისტრაცია</a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: "VacancyInfo",
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>

</style>